/* Cairo Regular */
@font-face {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 400;
  src: url("./Cairo-Regular.ttf") format("ttf");
}

/* Parisienne Regular */
@font-face {
  font-family: "Parisienne";
  font-style: normal;
  font-weight: 400;
  src: url("./Parisienne-Regular.ttf") format("ttf");
}

/* Poppins Light, Regular, Medium, Semibold, Bold, and Black */

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("./Poppins-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("./Poppins-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("./Poppins-SemiBold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("./Poppins-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("./Poppins-Black.ttf") format("ttf");
}

/* Roboto Light, Regular, Medium, Bold */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("./Roboto-Light.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Roboto-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./Roboto-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("./Roboto-Bold.ttf") format("ttf");
}
